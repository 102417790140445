require('./bootstrap');
require('./jquery.countTo');

import AOS from 'aos';
AOS.init();

// Funciones

	function go(pos){
		$("body,html").animate({ scrollTop: pos }, 200);
	}

// Scroll Navbar
$(".go").click(function(e){
	e.preventDefault();
	var ida = $(this).attr('href');
	var pos = $(ida).offset().top;
	go(pos);
});


var band = true;
$(window).scroll(function(){

	// Counters
	var posElem = $('#counters').offset().top;
	if( ($(this).scrollTop() > (posElem-700)) && band ){
		$('.counter').countTo();
		band = false;
	}

});
